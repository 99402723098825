import MUIButton from "@mui/material/Button";

import styles from "./Button.module.scss";
import LoaderMarvy from "components/atoms/LoaderMarvy";
import { useTheme } from "@mui/material";

export default function Button({
  children,
  fullWidth,
  className,
  secondary,
  gradient,
  marginBottom,
  loading,
  color = "primary",
  ...props
}) {
  const theme = useTheme();
  const colorStyles = {
    primary: {
      color: "#282b28",
      backgroundColor: "#eee",
    },
    error: {
      backgroundColor: "#00000000 !important",
      color: `${theme.palette.error.main} !important`,
      border: `1px solid ${theme.palette.error.main} !important`,
      "&:hover": {
        backgroundColor: `${theme.palette.error.main}33 !important`,
      },
      "&:disabled": {
        backgroundColor: `${theme.palette.error.main}33 !important`,
      },
    },
  };

  return (
    <MUIButton
      {...props}
      className={`${styles.btn} ${className} 
      ${props.variant === "text" ? styles.textButton : ""}
      ${secondary ? styles.secondary : ""}
      ${gradient ? styles.gradient : ""}
      ${marginBottom ? styles.marginBottom : ""}
      `}
      sx={{
        fontWeight: "700",
        ...(fullWidth ? { width: "100%" } : {}),
        ...(colorStyles[color] || {}),
        ...(props.sx || {}),
      }}
    >
      {children}
      {loading && <LoaderMarvy />}
    </MUIButton>
  );
}

import { CircularProgress } from "@mui/material";

export default function LoaderMarvy({ show = true, sx, ...props }) {
  return show ? (
    <CircularProgress
      size={24}
      thickness={5}
      sx={{
        mx: "12px",
        animationDuration: "800ms",
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        borderRadius: "50%",
        "& .MuiCircularProgress-circleIndeterminate": {
          strokeLinecap: "round",
        },
        ...sx,
      }}
      color="secondary"
      disableShrink
      {...props}
    />
  ) : null;
}
